import mainStore from '@/store'
// eslint-disable-next-line import/extensions
import warehouseModule from '@/store/main/warehouse'
import { FLAT_PICKER } from '@/views/components/DynamicForm/constants'

export default function config() {
  //   Constants
  const FULFILLMENT_ORDERS = 'Fulfillment Orders'
  const MODULE_NAME = 'warehouse-orders'

  const store = mainStore
  const model = warehouseModule

  // don't change the order of the keys, it's important. You can add new keys or change the values
  const ORDER_TYPES_KV = {
    TO: 'TO',
    SM: 'SM',
    RO: 'RO',
    HO: 'HO',
    PO: 'PO',
    SR: 'SR',
    SO: 'SO',
    DS: 'DS',
  }

  const orderTypes = {
    [ORDER_TYPES_KV.TO]: {
      redirectUrl: 'warehouse-transfer-pick',
      urlAction: 'orders/transfer',
    },
    [ORDER_TYPES_KV.SM]: {
      redirectUrl: 'warehouse-service-order',
      urlAction: 'service-order',
    },
    [ORDER_TYPES_KV.SO]: {
      redirectUrl: 'warehouse-rental-sales-pick',
      urlAction: 'orders/rental-sale-v2',
    },
    [ORDER_TYPES_KV.RO]: {
      redirectUrl: 'warehouse-rental-sales-pick',
      urlAction: 'orders/rental-sale-v2',
    },
    [ORDER_TYPES_KV.HO]: {
      redirectUrl: 'warehouse-rental-sales-pick',
      urlAction: 'orders/rental-sale-v2',
    },
    [ORDER_TYPES_KV.DS]: {
      redirectUrl: null,
      urlAction: 'orders/rental-sale-v2',
    },
    [ORDER_TYPES_KV.PO]: {
      redirectUrl: null,
      urlAction: 'orders/purchases',
    },
    [ORDER_TYPES_KV.SR]: {
      redirectUrl: 'sub-rent-pick',
      urlAction: 'orders/sub-rental',
    },
  }

  function orderPropsByType(orderType) {
    if (typeof orderType !== 'string') return null
    return orderTypes[orderType.toUpperCase()]
  }

  function handleOrderStatus(ctx, order) {
    const orderType = order.order_type.toUpperCase()
    const getOrderType = orderPropsByType(order.order_type)
    const fulfillmentState = order?.fulfillment_state
    const fulfillmentStatus = order?.fulfillment_status

    // Define redirect conditions
    const inBoundConditions = [
      fulfillmentState >= 8 || (fulfillmentState === 7 && fulfillmentStatus === 4),
      ([ORDER_TYPES_KV.SR, ORDER_TYPES_KV.PO].includes(orderType) && fulfillmentState >= 6) || (fulfillmentState === 5 && fulfillmentStatus === 4),
      ([ORDER_TYPES_KV.RO, ORDER_TYPES_KV.HO, ORDER_TYPES_KV.SO].includes(orderType) && fulfillmentState >= 7) || (fulfillmentState === 6 && fulfillmentStatus === 4),
    ]

    const dispatchConditions = [
      fulfillmentState === 7,
      ([ORDER_TYPES_KV.SR, ORDER_TYPES_KV.PO].includes(orderType) && fulfillmentState === 5),
      ([ORDER_TYPES_KV.RO, ORDER_TYPES_KV.HO, ORDER_TYPES_KV.SO].includes(orderType) && fulfillmentState === 6),
    ]

    const packConditions = [
      fulfillmentState === 6,
      ([ORDER_TYPES_KV.SR, ORDER_TYPES_KV.PO].includes(orderType) && fulfillmentState === 4),
      ([ORDER_TYPES_KV.RO, ORDER_TYPES_KV.HO, ORDER_TYPES_KV.SO].includes(orderType) && fulfillmentState === 5),
      ([ORDER_TYPES_KV.DS].includes(orderType)),
    ]

    // Redirect to inbound order if conditions are met
    if (inBoundConditions.some(Boolean)) {
      ctx.$router.push({ name: 'inbound-orders', params: { id: order.id, orderType: order.order_type } })
    } else if (dispatchConditions.some(Boolean)) {
      // Redirect to dispatch order if conditions are met
      ctx.$router.push({ name: 'dispatch-order', params: { id: order.id, orderType: order.order_type } })
    } else if (packConditions.some(Boolean)) {
      // Redirect to pack order if conditions are met
      ctx.$router.push({ name: 'home-warehouse-pack-order', params: { id: order.id, orderType: order.order_type } })
    } else {
      // Redirect to pick order if conditions are met
      if (!getOrderType?.urlAction || !getOrderType?.redirectUrl) return

      const payload = {
        id: order.id,
        urlAction: getOrderType.urlAction,
      }

      ctx.sendNotification(ctx, payload, `${MODULE_NAME}/startFulfillment`, 'Successfully started')
        .catch(err => ctx.errorNotification(ctx, err))
        .finally(() => {
          ctx.$router.push({ name: getOrderType.redirectUrl, params: { id: order.id } })
        })
    }
  }

  const warehouseWeeklyOrderFields = {
    selectDayOfWeek: {
      type: FLAT_PICKER,
      placeholder: 'Select a Date',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },

  }
  const filterTabs = [
    {
      title: 'Week',
      query: 'week',
      tabNumber: 2,
      pageTitle: 'Fulfillment Orders',
    },
    {
      title: 'List',
      query: 'list',
      tabNumber: 3,
      pageTitle: 'Fulfillment Orders List',
    },
  ]
  const filterTabsList = [
    {
      title: 'By Orders',
      query: 'by-orders',
      tabNumber: 2,
      pageTitle: 'Fulfillment List By Orders',
    },
    {
      title: 'By Products',
      query: 'by-products',
      tabNumber: 3,
      pageTitle: 'Fulfillment List by Products',
    },
  ]

  const statusFilterTab = [
    {
      icon: 'LPickIcon',
      query: 'pick',
      title: 'Pick status order',
    },
    {
      icon: 'LBoxIcon',
      query: 'in-box',
      title: 'Pack status order',
    },
    {
      icon: 'LTruckIcon',
      query: 'on-truck',
      title: 'Dispatch status order',
    },
    {
      icon: 'LInOutIcon',
      query: 'on-in-out',
      title: 'Transfer status order',
    },
    {
      icon: 'LWarehouseIcon',
      query: 'warehouse',
      title: 'Receive status order',
    },
    {
      icon: 'LUnPuckIcon',
      query: 'in-un-puck',
      title: 'Unpack status order',
    },
    {
      icon: 'LBoxSentIcon',
      query: 'sent',
      title: 'Put back status order',
    },
    {
      icon: 'LDangerIcon',
      query: 'danger',
      title: 'Conflict status order',
    },
  ]
  const ORDERS_STATUS_NO_ACTION = Object.freeze({
    id: 'no_action',
    status: 0,
    title: 'To be done',
    style: {
      color: '#D9D9D9',
      styleName: 'ORDERS_STATUS_NO_ACTION',
    },
  })
  const ORDERS_STATUS_IN_PROGRESS = Object.freeze({
    id: 'in_progress',
    status: 1,
    title: 'In progress',
    style: {
      color: '#4E1476',
      styleName: 'ORDERS_STATUS_IN_PROGRESS',
    },
  })
  const ORDERS_STATUS_ATTENTION = Object.freeze({
    id: 'attention',
    status: 2,
    title: 'Attention!',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_ATTENTION',
    },
  })
  const ORDERS_STATUS_WARNING = Object.freeze({
    id: 'warning',
    status: 3,
    title: 'Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_WARNING',
    },
  })
  const ORDERS_STATUS_READY = Object.freeze({
    id: 'ready',
    status: 4,
    title: 'Ready',
    style: {
      color: '#00B139',
      styleName: 'ORDERS_STATUS_READY',
    },
  })
  const ORDERS_STATUS_VOID = Object.freeze({
    id: 'void',
    status: 5,
    title: 'Void',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_VOID',
    },
  })

  const orderStatesAndStatuses = [
    { ...ORDERS_STATUS_NO_ACTION },
    { ...ORDERS_STATUS_IN_PROGRESS },
    { ...ORDERS_STATUS_ATTENTION },
    { ...ORDERS_STATUS_WARNING },
    { ...ORDERS_STATUS_READY },
    { ...ORDERS_STATUS_VOID },
  ]

  return {
    FULFILLMENT_ORDERS,
    MODULE_NAME,
    store,
    model,
    filterTabs,
    filterTabsList,
    statusFilterTab,
    orderStatesAndStatuses,
    warehouseWeeklyOrderFields,
    orderTypes,
    handleOrderStatus,
    ORDER_TYPES_KV,
    orderPropsByType,
  }
}
